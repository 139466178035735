<template>
  <div class="right">
    <span class="spen">个人基本信息</span>
    <div class="daohang">
      <ul class="ul">
        <router-link :to="{ name: 'User' }" tag="li">个人基本信息</router-link>
        <router-link :to="{ name: 'Safe' }" tag="li">账户安全</router-link>
        <router-link :to="{ name: 'Bind' }" tag="li">账号绑定</router-link>
        <router-link :to="{ name: 'Address' }" tag="li">收货地址</router-link>
      </ul>
    </div>
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="yonghu">
        <div class="yonghu_L">用户名：</div>
        <div class="yonghu_R">{{ list.userName }}</div>
      </div>
      <div class="denglu">
        <div class="denglu_L">手机号：</div>
        <div class="denglu_C">
          <el-input
            size="mini"
            v-model="list.phone"
            :disabled="jinyong"
          ></el-input>
        </div>
        <!-- <div class="denglu_R">
        <span class="xiugai" @click="xiugai">修改</span>可用于登录，请牢记
      </div> -->
      </div>
      <div class="denglu">
        <div class="denglu_L">昵称：</div>
        <div class="denglu_C">
          <el-input size="mini" v-model="nickName"></el-input>
        </div>
      </div>
      <div class="denglu">
        <div class="denglu_L">所属公司：</div>
        <div class="denglu_C">
          <el-input size="mini" v-model="companyName"></el-input>
        </div>
      </div>
      <div class="denglu">
        <div class="denglu_L">性别：</div>
        <div class="denglu_C">
          <el-radio v-model="radio" label="0">男</el-radio>
          <el-radio v-model="radio" label="1">女</el-radio>
        </div>
      </div>

      <div class="denglu">
        <div class="denglu_L">生日：</div>
        <div class="denglu_C">
          <el-date-picker v-model="birth" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
      </div>
      <div class="denglu">
        <div class="denglu_L">头像：</div>
        <div class="denglu_C">
          <el-upload
            class="avatar-uploader"
            :action="picurl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <Uimage v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>

      <div class="tijiao" @click="tijiao">提交</div>
      <el-button :plain="true" v-show="false"></el-button>
    </div>
  </div>
</template>
<script>
import { post, get } from '@/utils/request';
import { baseURL } from '@/utils/request';
export default {
  data() {
    return {
      value1: '',
      imageUrl: '',
      coverImg: '',
      form: {
        name: '',
      },
      input: 'bw_ZyFGTFKYQDpP',
      name: '李华5632',
      radio: '0',
      jinyong: true,
      list: [],
      nickName: '',
      companyName:'',
      birth: '',
      isLoading: true,
      picurl: baseURL + 'f/api/frontCommon/avatar',
    };
  },
  created() {
    this.getorder();
  },
  methods: {
    getorder() {
      get('api/member/selMemberInfo').then((res) => {
        this.imageUrl = res.data.data.headPhoto;
        this.isLoading = false;
        this.list = res.data.data;
        this.radio = res.data.data.gender;
        this.nickName = res.data.data.nickName;
        this.companyName = res.data.data.companyName;
        this.birth = res.data.data.birth;
      });
    },
    tijiao() {
      this.isLoading = true;
      post('api/member/addSave', {
        memberId: this.list.memberId,
        nickName: this.nickName,
        companyName: this.companyName,
        headPhoto: this.coverImg,
        gender: this.radio,
        birth: this.birth,
      }).then((res) => {
        this.isLoading = false;
        if (res.data.msg == '操作成功') {
          this.$message.success({
            message: '修改成功',
          });
          this.getorder();
        } else {
          this.$message.error({
            message: '修改失败',
          });
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.coverImg = res.fileName;
      this.imageUrl = res.url;
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        'image/jpg' ||
        'image/webp' ||
        'image/png';
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error({
          message: '图片格式不正确!(只能包含jpg，png，webp，JPEG)',
        });
      }
      if (!isLt2M) {
        this.$message.error({
          message: '上传头像图片大小不能超过 10MB!',
        });
      }
      return isJPG && isLt2M;
    },
    xiugai() {
      this.jinyong = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.right {
  width: 1006px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  font-size: 14px;
  color: #444;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }

  .daohang {
    font-size: 15px;
    color: #272727;
    margin-right: 25px;
    height: 30px;

    .ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      width: 400px;
      li {
        list-style: none;
        cursor: pointer;
        &.router-link-exact-active.router-link-active {
          color: #3661fe;
        }
      }
    }
  }
  .yonghu {
    display: flex;
    font-size: 14px;
    margin-top: 43px;
    .yonghu_L {
      color: #444;
      width: 80px;
    }
    .yonghu_R {
      color: #333;
    }
  }
  .denglu {
    margin-top: 30px;
    display: flex;
    align-items: center;
    .denglu_L {
      width: 80px;
    }
    .denglu_C {
      width: 30%;
    }
    .denglu_R {
      margin-left: 20px;
      color: #999;
      .xiugai {
        color: #1951fe;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
  .tijiao {
    width: 92px;
    height: 30px;
    background: #1850ff;
    color: #fff;
    border-radius: 5px;
    line-height: 30px;
    text-align: center;
    margin-top: 60px;
    margin-left: 150px;
  }
}
</style>
